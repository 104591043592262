import React from "react"
import { Link } from "@StarberryUtils";

import Header from "../../../../components/Header/Header";
import Footer from "../../../../components/Footer/Footer";
import StickyFooter from "../../../../components/Footer/StickyFooter";
import NewsLetter from "../../../../components/Home/NewsLetter/NewsLetter";
import Breadcrumb from "../../../../components/Home/Breadcrumb/Breadcrumb";
import Results from "../../../../templates/commercial-search-results-map-template";


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../scss/bootstrap-overrides.scss";
import "../../../../scss/grunticon.scss";
import "../../../../scss/global.scss";
import "../../../../scss/custom.scss";

const CommercialSalesMap = (props) => {

	const [state, setState] = React.useState({
        showMenu: false
    })
    
    const handlerClick = () => {
        setState({ ...state, showMenu: !state.showMenu })
    }
    const fullpath = props.location.pathname
    const location = props.location
  return (
      	<div className={`${state.showMenu ? "open-search-block commercial" : "commercial"}`}>
            <Header showMenu={state.showMenu} handlerClick={handlerClick} fixed={true} />
            <Results location={location} locationname="/properties-map/commercial/for-sale/" pcategorytype="commercial" fullpathname={fullpath} ptype="sales" ptypetag="for-sale"/>
            <Breadcrumb data={''} name={`Properties For sale`} tag='detail-page'/>  
            <NewsLetter />
            <Footer popularSearch={"Popular_Search_Commercial"} />
            {/* <StickyFooter /> */}

		</div>
  )
}

export default CommercialSalesMap